.scroll-up-button {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	position: fixed;
	bottom: 25px;
	right: 25px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	display: grid;
	place-items: center;
	cursor: pointer;
    z-index: 10000;
}

.scroll-up-button span {
	display: block;
	height: calc(100% - 10px);
	width: calc(100% - 10px);
	background-color: #ffffff;
	border-radius: 50%;
	display: grid;
	place-items: center;
	font-weight: 600;
	font-size: 20px;
	color: black
}